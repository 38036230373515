export const AdditionalProjectsList = [
	{
		id: 0,
		title: 'AADP1',
		src: '/imgs/AddWorkGifs/EmployeeTraackerCropped.gif',
		url: '#AADP1',
		cName: 'AddWork-IMG',
		tNailName: 'React Employee Directory',
		tNailClass: 'Tnail-CSSADD',
		modalTitle: 'React Employee Directory',
		modalGif: '/imgs/AddWorkGifs/EmployeeTraackerModal.gif',
		modalDesc:
			'Built a dynamic employee directory in React using data populated from the randomuser.me API. Taking creative license, I stylized this project to look like a terminal found in the Alien(s) movie franchize, specifically the Weyland-Yutani Corporation, the multinational conglomerate.',
		modalBuiltWith: 'React, Javascript, Axios, React-Bootstrap',
		githubLink: 'https://github.com/JeffQuit/ReactJS---Employee-Directory',
		deployedLink: 'https://thawing-fjord-36936.herokuapp.com/',
		privateRepo: false,
		delployed: true,
		imgClass: 'Work-IMG-CSS-Style-ADD',
		tNailButton: 'Work-IMG-Bottom-Button-ADD',
	},
	{
		id: 1,
		title: 'AADP2',
		src: '/imgs/AddWorkGifs/FitnessTnail.gif',
		url: '#AADP2',
		cName: 'AddWork-IMG',
		tNailName: 'Fitness Tracker',
		tNailClass: 'Tnail-CSSADD',
		modalTitle: 'Fitness Tracker',
		modalGif: '/imgs/AddWorkGifs/FitnessModalGif.gif',
		modalDesc: 'Fitness exercise tracking application that takes in workout information, cardio or resistance, and logs information to a NoSQL MongoDB. Logged workouts can be viewed in a dashboard that displays visual projections and progress mapping of logged workouts over time.',
		modalBuiltWith: 'Javascript, MongoDB, Express, Node.js, Bootstrap, HTML, CSS ',
		githubLink: 'https://github.com/JeffQuit/Mongo-DB---Fitness-Tracker-Application',
		deployedLink: 'https://hidden-chamber-07445.herokuapp.com/',
		privateRepo: false,
		delployed: true,
		imgClass: 'Work-IMG-CSS-Style-ADD',
		tNailButton: 'Work-IMG-Bottom-Button-ADD',
	},
	{
		id: 2,
		title: 'AADP3',
		src: '/imgs/AddWorkGifs/WeatherDashCropped.gif',
		url: '#AADP3',
		cName: 'AddWork-IMG',
		tNailName: 'Weather Dashboard',
		tNailClass: 'Tnail-CSSADD',
		modalTitle: 'Weather Dashboard',
		modalGif: '/imgs/AddWorkGifs/WeatherDashModal.gif',
		modalDesc: 'Weather Dashboard Application that utilizes the Open Weather Map API to provide a 5 day forecast from user submitted cities. Submitted cities area saved and can be clicked to pull up previously viewed weather forecasts.',
		modalBuiltWith: 'Javascipt, jQuery, Axios, Bulma, HTML, CSS',
		githubLink: 'https://github.com/JeffQuit/API---Weather-Forecast-Application',
		deployedLink: 'https://jeffquit.github.io/API---Weather-Forecast-Application/',
		privateRepo: false,
		delployed: true,
		imgClass: 'Work-IMG-CSS-Style-ADD',
		tNailButton: 'Work-IMG-Bottom-Button-ADD',
	},
	{
		id: 3,
		title: 'AADP4',
		src: '/imgs/AddWorkGifs/TeamGeneratorCropped.gif',
		url: '#AADP4',
		cName: 'AddWork-IMG',
		tNailName: 'Team Profile Generator',
		tNailClass: 'Tnail-CSSADD',
		modalTitle: 'Team Profile Generator',
		modalGif: '/imgs/AddWorkGifs/TeamGeneratorModal.gif',
		modalDesc:
			'Node.js Command Line Interface team builder application that prompts the user to create a team with one manager and as many engineers and intens as they need for the team. Once the CLI has executed, it exports an HTML page populated with the submitted information from the CLI. Includes testing via the Jest node package',
		modalBuiltWith: 'Node.js, Javascript, Inquirer, Jest, Custom CLI Color Display Functionality, HTML, CSS, Bootstrap ',
		githubLink: 'https://github.com/JeffQuit/OOP---Team-Profile-Generator',
		deployedLink: '',
		privateRepo: false,
		delployed: false,
		imgClass: 'Work-IMG-CSS-Style-ADD',
		tNailButton: 'Work-IMG-Bottom-Button-ADD',
	},
	{
		id: 4,
		title: 'AADP5',
		src: '/imgs/AddWorkGifs/ReadMeGenCropped.gif',
		url: '#AADP5',
		cName: 'AddWork-IMG',
		tNailName: 'README.MD Generator',
		tNailClass: 'Tnail-CSSADD',
		modalTitle: 'README.MD Generator',
		modalGif: '/imgs/AddWorkGifs/ReadMeGenModal.gif',
		modalDesc: 'CLI Based README.MD file generator that uses Inquirer to prompt the user for sections to be populated on a README file template. Includes 4 different licenses that the user can choose from for their application.',
		modalBuiltWith: 'Node.js, Javascript, Moment.js',
		githubLink: 'https://github.com/JeffQuit/Node.js---Read-Me-Generator-CLI',
		deployedLink: '',
		privateRepo: false,
		delployed: false,
		imgClass: 'Work-IMG-CSS-Style-ADD',
		tNailButton: 'Work-IMG-Bottom-Button-ADD',
	},
	{
		id: 5,
		title: 'AADP6',
		src: '/imgs/AddWorkGifs/KrustyKrabCropped.gif',
		url: '#AADP6',
		cName: 'AddWork-IMG',
		tNailName: 'Krusty Krab Simulator',
		tNailClass: 'Tnail-CSSADD',
		modalTitle: 'Krusty Krab Simulator',
		modalGif: '/imgs/AddWorkGifs/KrustyKrabModal.gif',
		modalDesc:
			'Originally assigned as a resturant burger eating simulator, I took creative license to stylize my version of this project in the theme of SpongeBob SquarePants restaurant, The Krusty Krab. Users can submit a meal, then eat the meal using the fork icon. Eaten meals are moved to the left table.',
		modalBuiltWith: 'Javascript, jQuery, Node.js, Express, Handlebars, MySQL, Bootstrap, HTML, CSS',
		githubLink: 'https://github.com/JeffQuit/Express-Handlebars---Eat-Da-Burger-Krusty-Krab-Version-',
		deployedLink: 'https://secret-river-99208.herokuapp.com/',
		privateRepo: false,
		delployed: true,
		imgClass: 'Work-IMG-CSS-Style-ADD',
		tNailButton: 'Work-IMG-Bottom-Button-ADD',
	},
];
