import React, { Component } from 'react';
import './Footer.css';

export default class Footer extends Component {
	render() {
		return (
			<footer>
				<section className="Footer-Bottom">&copy; JeffQuittman.com | Designed by Jeff Quittman</section>
			</footer>
		);
	}
}
