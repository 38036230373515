export const FeaturedProjects = [
	{
		id: 0,
		title: `Gera's Auto Detailing`,
		src: '/imgs/ClientGifs/GeraAutoWebsiteCropped.gif',
		url: '#FP0',
		cName: 'Work-IMG',
		tNailName: `Gera's Auto Detailing`,
		tNailClass: 'Tnail-CSS',
		modalTitle: `Gera's Auto Detailing`,
		modalGif: '/imgs/ClientGifs/GeraAutoWebsiteSrc.gif',
		modalDesc: 'Built web presense for local mobile vehicle detailing service with service package menu and quote/booking request feature to meet the business model of the client. Integrated Sendgrid trigger emails and admin panel for owner to view servies requested through app.',
		modalBuiltWith: 'MERN Stack: MongoDB, Express, React, Node.js. Sendgrid, HTML/CSS, Material Design Bootstrap, Atlas',
		githubLink: 'https://github.com/JeffQuit/MERN---Project-3---Mobile-Vehicle-Detail-Application',
		deployedLink: 'https://sleepy-castle-35602.herokuapp.com/',
		privateRepo: false,
		delployed: true,
		imgClass: 'Work-IMG-CSS-Style',
		tNailButton: 'Work-IMG-Bottom-Button',
	},
	{
		id: 1,
		title: 'Dubsado Scheduler',
		src: '/imgs/ClientGifs/DubsadoCropped.gif',
		url: '#FP1',
		cName: 'Work-IMG',
		tNailName: 'Dubsado Scheduler',
		tNailClass: 'Tnail-CSS',
		modalTitle: 'Dubsado Scheduler',
		modalGif: '/imgs/ClientGifs/DubSchedulerModal.gif',
		modalDesc: 'Developed a new scheduling feature for Los Angeles based SAAS start-up Dubsado. Built on top of FullCalendar js, allows users to schedule new appointments and receive an appointment confirmation email.',
		modalBuiltWith: 'Javascript, jQuery, Node.js, Express, Handlebars, MySQL, Microsoft Azure, HTML, CSS, uiKit, FullCalendar JS',
		githubLink: '',
		deployedLink: '',
		privateRepo: true,
		delployed: false,
		imgClass: 'Work-IMG-CSS-Style',
		tNailButton: 'Work-IMG-Bottom-Button',
	},
	{
		id: 2,
		title: 'FP2',
		src: '/imgs/ClientGifs/TIOCropped.gif',
		url: '#FP2',
		cName: 'Work-IMG',
		tNailName: 'Tuition.io One Pager',
		tNailClass: 'Tnail-CSS',
		modalTitle: 'Tuition.io One Pager Application',
		modalGif: '/imgs/ClientGifs/TIOModal.gif',
		modalDesc: 'Developed an information repository platform for Los Angeles start-up Tuition.io that consolidates partner program information into a one stop access application. The One Pager Application increase efficiency for the Tuition.io Customer Support Team when accessing information.',
		modalBuiltWith: 'Javascript, jQuery, Node.js, Express, Handlebars, MySQL, Microsoft Azure, HTML, CSS, uiKit, FullCalendar JS',
		githubLink: '',
		deployedLink: '',
		privateRepo: true,
		delployed: false,
		imgClass: 'Work-IMG-CSS-Style',
		tNailButton: 'Work-IMG-Bottom-Button',
	},
];
